/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-01-27",
    versionChannel: "nightly",
    buildDate: "2024-01-27T00:14:53.168Z",
    commitHash: "941e60aef506b1643973d8e91f3f9bae0f270988",
};
